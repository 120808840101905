import Axios from 'axios'
import wx from 'weixin-js-sdk'
import store from '../store/index'
var url = location.href.split('#')[0]
Axios.get(store.state.ocApi + 'wx/jssdk', {
		params: {
			url
		}
	})
	.then(res => {
		var data = res.data.data
		wx.config({
			debug: false,
			appId: data.appId,
			timestamp: data.timestamp,
			nonceStr: data.nonceStr,
			signature: data.signature,
			jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareWeibo']
			// 所有要调用的 API 都要加到这个列表中
		});
	})

export default function (dataForWeixin) {
	wx.ready(function() {
		// 2. 分享接口
		// 2.1 监听“分享给朋友”及“分享到QQ”
		wx.updateAppMessageShareData({
			title: dataForWeixin.title,
			desc: dataForWeixin.desc,
			link: dataForWeixin.linkurl,
			imgUrl: dataForWeixin.img,
			success: function success() {
				dataForWeixin.success && dataForWeixin.success();
			}
		});


		// 2.2 监听“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
		wx.updateTimelineShareData({
			title: dataForWeixin.title,
			link: dataForWeixin.linkurl,
			imgUrl: dataForWeixin.img,
			success: function success() {
				dataForWeixin.success && dataForWeixin.success();
			},
		});


		// 2.3 监听“分享到微博”按钮点击、自定义分享内容及分享结果接口
		wx.onMenuShareWeibo({
			title: dataForWeixin.title,
			desc: dataForWeixin.desc,
			link: dataForWeixin.linkurl,
			imgUrl: dataForWeixin.img,
			success: function success() {
				dataForWeixin.success && dataForWeixin.success();
			},
		});


	});
}


