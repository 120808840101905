<template>
	<div class="page">
		<div class="video">
			<img src="../../assets/marketing/video.gif" class="bgimg" />
		</div>
		<div class="bg-wrap">
			<img src="../../assets/marketing/bg1.jpg" class="bgimg" />
			<a href="https://wq.xiaoyuan.com.cn/addons/hxq_lottery/mobileapi.php?do=redirect&i=2&actid=2&op=index"><img src="../../assets/marketing/bg2.jpg" class="bgimg"/></a>
			<img src="../../assets/marketing/bg3.jpg" class="bgimg" />
		</div>
	</div>
</template>

<script>
import wxShare from '../../common/wxsdk.js';
export default {
	name: 'marketing202004',
	data() {
		return {};
	},
	created() {
		wxShare({
			title: "我的好运分你一半",//分享标题
			desc: "小院生活 映献渭南四重礼 100%中奖",//分享描述
			linkurl: "https://m.4009005258.com/marketing/202004",//分享链接
			img: "https://xcx-1257793586.cos.ap-beijing.myqcloud.com/advertise/marketing/share.jpg",//分享图片
			success:function(){
				
			},
		});
	},
	methods: {}
};
</script>

<style lang="scss" scoped>
.page {
	width: 100%;
	.bg-wrap {
		width: 100%;
		position: relative;
	}
	.bgimg {
		width: 100%;
	}
	.btn {
		width: 100%;
		position: absolute;
		top: 64vh;
		z-index: 99;
		left: 0;
		text-align: center;
		.btnimg {
			width: 86.67%;
			margin: auto;
		}
	}
}
</style>
